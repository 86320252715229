import React from "react"
 
import InlineLogo from "../components/inlinelogo"


const SmilesPage = () => (
    <div css={{
      maxWidth: '80%',
      margin: '0 auto',
      }}
    >
        <div css={{ margin: `20px 10px`, maxWidth: `250px`}}>
          <InlineLogo />
        </div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfeAMNWqI5pDlqMWUZ3M8DS4yRHl914Jq4bWj8JmLHS1kBj3g/viewform?embedded=true" width="640" height="1052" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
    </div>
)

export default SmilesPage
